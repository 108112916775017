import React, { useState, useEffect } from 'react';
import { FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './ContactUs.scss';
import AppointmentForm from '../../components/AppointmentForm/AppointmentForm';
import AppointmentFormManager from '../../components/AppointmentFormManager/AppointmentFormManager';

const clinics = [
  {
    id: 'kukatpally',
    name: "Unidental - Kukatpally, Hyderabad",
    address: "2nd Floor, Shree Krishna Diagnostics, Beside Kalamandir, KPHB Main Rd, Kukatpally, Hyderabad, Telangana 500072, India",
    phone: "+91 6305971445",
    email: "unidentalindia@gmail.com",
    whatsapp: "+916305971445", 
    location: { lat: 17.49606, lng: 78.40053 },
  },
  {
    id: 'anantapur',
    name: "Unidental - Anantapur",
    address: "1-1348 Near crescent function Hall, 80 Feet Rd, opp. to Sakshi, Anantapur, Andhra Pradesh 515004, India",
    phone: "+91 7075890089",
    email: "unidentalindia@gmail.com",
    whatsapp: "+917075890089", 
    location: { lat: 14.66906, lng: 77.58586 },
  },
  {
    id: 'goa',
    name: "Unidental - Goa",
    address: "Manipal Hospitals Goa, Dr E Borges Rd, Dona Paula, Durgavado, Goa 403004, India",
    phone: "+91 8326632500",
    email: "unidentalindia@gmail.com",
    whatsapp: "+918326632500", 
    location: { lat: 15.46027, lng: 73.81327 },
  },
];

const getMapUrl = (location) => {
  const { lat, lng } = location;
  return `https://maps.google.com/maps?q=${lat},${lng}&z=14&output=embed`;
};

const ContactUs = () => {
  const navigate = useNavigate();
  const { openForm, closeForm, isFormOpen, selectedLocation } = AppointmentFormManager();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.title = "Contact Unidental | Get in Touch for Dental Care"; 

  const [selectedClinic, setSelectedClinic] = useState(clinics[0].location);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClinicClick = (location, index) => {
    setSelectedClinic(location);
    setSelectedIndex(index);
  };

  const handleWhatsAppClick = (e, whatsapp) => {
    e.stopPropagation();
    window.open(`https://wa.me/${whatsapp.replace(/\+/g, '')}`, '_blank');
  };

  const handlePhoneClick = (e, phone) => {
    e.stopPropagation();
    window.location.href = `tel:${phone}`;
  };

  const handleEmailClick = (e, email) => {
    e.stopPropagation();
    window.location.href = `mailto:${email}`;
  };

  const handleScheduleClick = (e, clinicId) => {
    e.stopPropagation();
    // Map clinic IDs to location names
    const locationMap = {
      'kukatpally': 'Kukatpally(HYD)',
      'anantapur': 'Anantapur',
      'goa': 'Goa'
    };
    openForm(locationMap[clinicId]);
  };

  const handleOfficeDetailsClick = (e, clinicId) => {
    e.stopPropagation();
    const routeMap = {
      'kukatpally': '/locations/Hyderabad',
      'anantapur': '/locations/Anantapur',
      'goa': '/locations/Goa'
    };
    navigate(routeMap[clinicId]);
  };

  return (
    <div className="contact-us">
      <meta name="description" content="Have questions or need to schedule an appointment? Contact Unidental today. We're here to provide answers and quality dental care." /> 
      <div className="clinic-list">
        {clinics.map((clinic, index) => (
          <div
            key={index}
            className={`clinic-card ${index === selectedIndex ? 'selected' : ''}`}
            onClick={() => handleClinicClick(clinic.location, index)}
          >
            <div className="clinic-info">
              <h3>{clinic.name}</h3>
              <p>{clinic.address}</p>
              <div className="contact-row">
                <div className="contact-item" onClick={(e) => handlePhoneClick(e, clinic.phone)}>
                  <FaPhone className="icon phone-icon" />
                  <span>{clinic.phone}</span>
                </div>
                <div className="contact-item" onClick={(e) => handleWhatsAppClick(e, clinic.whatsapp)}>
                  <FaWhatsapp className="icon whatsapp-icon" />
                  <span>{clinic.whatsapp}</span>
                </div>
              </div>
              <div className="contact-item" onClick={(e) => handleEmailClick(e, clinic.email)}>
                <FaEnvelope className="icon email-icon" />
                <span>{clinic.email}</span>
              </div>
              <div className="button-row">
                <button 
                  className="action-button schedule-button"
                  onClick={(e) => handleScheduleClick(e, clinic.id)}
                >
                  Schedule
                </button>
                <button 
                  className="action-button details-button"
                  onClick={(e) => handleOfficeDetailsClick(e, clinic.id)}
                >
                  Office Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="map-container">
        <iframe
          src={getMapUrl(selectedClinic)}
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        ></iframe>
      </div>
      {isFormOpen && <AppointmentForm 
        closeForm={closeForm} 
        selectedLocation={selectedLocation}
      />}
    </div>
  );
};

export default ContactUs;