import React, { useRef, useEffect,  useState  } from 'react';
import { motion, useInView } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importing Helmet for SEO
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import PromoBanner from '../../components/PromoBanner/PromoBanner';
import SymptomsAndTreatmentsGrid from '../../components/Services/SymptomsAndTreatmentsScrollable';
import BestServices from '../../components/BestServices/BestServices';
import Reviews from '../../components/Reviews/Reviews';
import { NAVBAR_HEIGHT, SCROLL_OFFSET } from '../../components/Navbar/Navbar';
import AppointmentFormManager from '../../components/AppointmentFormManager/AppointmentFormManager';
import AppointmentForm from '../../components/AppointmentForm/AppointmentForm';
import Captcha from '../../components/Captcha/Captcha';
import emailjs from 'emailjs-com';


import './HomePage.scss';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const slideUp = {
  hidden: { y: 100, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
};

const slideLeft = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.8 } },
};

function HomePage({ openForm }) {
  const promoRef = useRef(null);
  const heroRef = useRef(null);
  const servicesRef = useRef(null);
  const bestServicesRef = useRef(null);
  const reviewsRef = useRef(null);

  const location = useLocation();

  const promoInView = useInView(promoRef, { once: true });
  const heroInView = useInView(heroRef, { once: true });
  const bestServicesInView = useInView(bestServicesRef, { once: true });
  const reviewsInView = useInView(reviewsRef, { once: true });

  useEffect(() => {
    if (location.state && location.state.scrollTo === 'services') {
      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        setTimeout(() => {
          const yOffset = -(NAVBAR_HEIGHT + SCROLL_OFFSET);
          const element = servicesSection;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          
          requestAnimationFrame(() => {
            window.scrollTo({
              top: Math.max(0, y),
              behavior: 'smooth'
            });
          });
        }, 300);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);
  const [formData, setFormData] = useState({
    location: location, // Default location
    name: '',
    email: '',
    phone: '',
    date: '',
    message: '',
    agreement: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const validateField = (name, value) => {
    let error = '';
    if (!value || value.trim() === '') {
      switch (name) {
        case 'name':
          return 'Name is required';
        case 'email':
          return 'Email is required';
        case 'phone':
          return 'Phone number is required';
        default:
          return '';
      }
    }
    switch (name) {
      case 'email':
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)) {
          error = 'Invalid email address';
        }
        break;
      case 'phone':
        if (!/^[6-9]\d{9}$/.test(value)) {
          error = 'Invalid phone number';
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: validateField(name, value) }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    Object.keys(formData).forEach((key) => {
      if (key !== 'message' && key !== 'agreement') {
        const error = validateField(key, formData[key]);
        if (error) {
          newErrors[key] = error;
          isValid = false;
        }
      }
    });
    setErrors(newErrors);
    return isValid;
  };

  const sendEmail = async () => {
    try {
      const result = await emailjs.send(
        'service_lffniaa', // Replace with your EmailJS service ID
        'template_5yrtjei', // Replace with your EmailJS template ID
        {
          location: formData.location,
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          date: formData.date,
          message: formData.message,
        },
        'YYf7gTHlbO0musIlg' // Replace with your EmailJS user ID
      );

      console.log('Email sent:', result.text);
      setIsSuccess(true);
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (validateForm() && formData.agreement) {
      setIsSubmitting(true);
      try {
        await sendEmail();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCaptchaValidate = (isValid) => {
    setIsCaptchaValid(isValid);
  };

  return (
    <div className="home-page">
      <Helmet>
        <title>The Best Dental Hospital in India | Unidental</title>
        <meta name="description" content="The Best Dental Hospital in India with branches in Hyderabad, Anantapur and Goa. We offer dental services like dental Implants, Dentures & Root Canal Treatments etc." />
        <meta name="keywords" content="Best Dental Hospital in India, Best Dental Services, Dental Care, Dental Solutions, Dental services in Hyderabad, Dental services in Goa, Dental services in Anantapur" />
      </Helmet>

      <motion.div
        ref={promoRef}
        variants={fadeIn}
        initial="hidden"
        animate={promoInView ? 'visible' : 'hidden'}
      >
        <PromoBanner
          text="New Patient Special!"
          subtext="Get a comprehensive exam and cleaning for only $99"
        />
      </motion.div>

      <motion.div
        ref={heroRef}
        variants={slideUp}
        initial="hidden"
        animate={heroInView ? 'visible' : 'hidden'}
      >
        <HeroBanner onOpenForm={openForm} />

      </motion.div>

              <div className="content">

          <div className="welcome-form">
            <h2>Book Appointment</h2>
            {isSuccess ? (
              <p className="success-message">
                Thank you for reaching out to us. We will respond to your inquiry as soon as possible.
              </p>
            ) : (
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Location:</label>
                    <select
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    >
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Anantapur">Anantapur</option>
                      <option value="Goa">Goa</option>
                    </select>
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="form-group">
                  <label>Name:</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Name"
                      required
                    />
                    {errors.name && <span className="error">{errors.name}</span>}
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="form-group">
                  <label>Email:</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      required
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                  </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                <div className="form-group">
                <label>Phone:</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    required
                  />
                  {errors.phone && <span className="error">{errors.phone}</span>}
                </div>
                </div>
                <div className="col-md-6">
                <div className="form-group">
                <label>Date:</label>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                <div className="form-group">
                  <label>Message:</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Additional Information"
                  ></textarea>
                </div>
                </div>
                <div className="col-md-6">
                <div className="form-group">
                  <Captcha onValidate={handleCaptchaValidate} />
                </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group checkbox-group">
                    <input
                      type="checkbox"
                      name="agreement"
                      checked={formData.agreement}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          agreement: e.target.checked,
                        }))
                      }
                      required
                    />
                    <span className='checkbox-content'>I agree to allow UNIDENTAL and its partners to contact me via phone, text message, and/or email.</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    type="submit"
                    disabled={isSubmitting || !isCaptchaValid}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
                <div className="clearfix"></div>
                </div>
              </form>
            )}
          </div>
        <motion.section
          id="services"
          ref={servicesRef}
          className="services"
          variants={slideUp}
          initial="hidden"
          animate="visible"
          style={{ 
            scrollMarginTop: `${NAVBAR_HEIGHT + SCROLL_OFFSET}px`,
            position: 'relative'
          }}
        >
          <h2>Our Services</h2>
          <SymptomsAndTreatmentsGrid />
        </motion.section>

        <motion.section
          ref={bestServicesRef}
          className="best-services"
          variants={slideLeft}
          initial="hidden"
          animate={bestServicesInView ? 'visible' : 'hidden'}
        >
          <h2>Why UNIDENTAL</h2>
          <BestServices />
        </motion.section>

        <motion.section
          ref={reviewsRef}
          className="reviews"
          variants={fadeIn}
          initial="hidden"
          animate={reviewsInView ? 'visible' : 'hidden'}
        >
          <h2>What our customers say</h2>
          <Reviews />
        </motion.section>
      </div>
    </div>
  );
}


export default HomePage;