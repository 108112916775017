import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Services.scss'; // Import SCSS

// Service Data
const services = [
  {
    id: 'general-dentistry',
    name: 'General Dentistry',
    description: 'Dental cleanings, Exams and X-rays, Cavity fillings, Fluoride treatments,Sealants and more',
    logo: require('../../assets/icons/General_Dentistry.png'), // Replace with your logo path
    route: '/services/general-dentistry',
  },
  {
    id: 'cosmetic-dentistry',
    name: 'Cosmetic Dentistry',
    description: 'Teeth whitening, veneers, Bonding, smile makeovers and Tooth reshaping',
    logo: require('../../assets/icons/Cosmetic_Dentistry.png'),
    route: '/services/cosmetic-dental-services',
  },
  {
    id: 'Orthodontics',
    name: 'Orthodontics',
    description: 'Traditional metal braces, Clear aligners, Retainers and Early orthodontic treatment for children',
    logo: require('../../assets/icons/Orthodontics.png'),
    route: '/services/best-invisalign-braces',
  },
  {
    id: 'Endodontics',
    name: 'Endodontics',
    description: 'Root canal therapy, Retreatment of failed root canals, and Apicoectomy',
    logo: require('../../assets/icons/Endodontics.png'),
    route: '/services/best-root-canal-treatments-in-india',
  },
  {
    id: 'Periodontics',
    name: 'Periodontics',
    description: 'Scaling and root planing, Gum grafting, Treatment of gum disease and Bone grafting',
    logo: require('../../assets/icons/Periodontics.png'), // Replace with your logo path
    route: '/services/gum-disease-treatments',
  },
  {
    id: 'Oral and Maxillofacial Surgery',
    name: 'Oral and Maxillofacial Surgery',
    description: 'Tooth extractions, Dental implants, Bone grafts,TMJ surgery and Jaw surgery',
    logo: require('../../assets/icons/Oral_Surgery.png'),
    route: '/services/oral-maxillofacial-surgery',
  },
  {
    id: 'Prosthodontics',
    name: 'Prosthodontics',
    description: 'Dental crowns, Bridges, Full and partial dentures,Implant-supported dentures and Veneers for damaged or discolored teeth',
    logo: require('../../assets/icons/Prosthodontics.png'),
    route: '/services/false-teeth-dentures',
  },
  {
    id: 'Oral Pathology',
    name: 'Oral Pathology',
    description: 'Oral cancer screenings,Diagnosis and biopsy of oral lesions and Management of benign or malignant oral diseases',
    logo: require('../../assets/icons/Oral_Pathology.png'),
    route: '/services/best-treatment-for-oral-diseases',
  },
  {
    id: 'Oral Medicine',
    name: 'Oral Medicine',
    description: 'TMJ disorder treatment,Diagnosis of oral lesions,Management of dry mouth and Treatment of oral infections',
    logo: require('../../assets/icons/Oral_Medicine.png'),
    route: '/services/best-treatment-for-mouth-ulcers',
  },
  {
    id: 'Dental Implants',
    name: 'Dental Implants',
    description: 'Single tooth implants,Implant-supported bridges,Full-arch dental implants and Mini implants',
    logo: require('../../assets/icons/Dental_Implants.png'),
    route: '/services/dental-implants',
  },
  {
    id: 'Laser Dentistry',
    name: 'Laser Dentistry',
    description: 'Laser Dentistry,Frenectomy,Cavity detection and treatment, Teeth whitening and Soft tissue surgery',
    logo: require('../../assets/icons/Laser_Dentistry.png'),
    route: '/services/dental-laser-treatment',
  },
  {
    id: 'Pediatric Dentistry',
    name: 'Pediatric Dentistry',
    description: 'First dental visits,Cleanings and exams,Fluoride treatments,Sealants and Early orthodontic screenings',
    logo: require('../../assets/icons/Pediatric_Dentistry.png'),
    route: '/services/kids-dentist',
  },
];

const Services = () => {
  const navigate = useNavigate();

  const handleCardClick = (route) => {
    navigate(route); // Navigate to the route
  };

  return (
    <div className="services-container">
      <div className="services-grid">
        {services.map((service) => (
          <div
            key={service.id}
            className="service-card"
            onClick={() => handleCardClick(service.route)}
          >
            <img src={service.logo} alt={service.name} className="service-logo" />
            <h3 className="service-name">{service.name}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
