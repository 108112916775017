import { motion } from 'framer-motion';
import './badges.scss'

const Badges = () => {
  const badges = [
    { emoji: '🦷', text: 'Advanced Dental Technology' },
    { emoji: '👨‍⚕️', text: 'Expert Dental Team' },
    { emoji: '⭐', text: '5-Star Patient Care' },
    { emoji: '🌟', text: 'Modern Facilities' },
    { emoji: '🤝', text: 'Personalized Care' }
  ];

  return (
    <motion.div
      className="badges-container"
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: { staggerChildren: 0.1 },
        },
      }}
    >
      {badges.map((badge, index) => (
        <motion.div
          key={index}
          className="badge"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="badge-circle">
            <span className="emoji">{badge.emoji}</span>
          </div>
          <div className='badge-content'>{badge.text}</div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Badges;